import React, { useEffect, useState } from 'react';

import { LikeButtonProps } from './type';
import CustomButton from '@/widgets/custom-button';
import { Cookie } from '@/utils/cookie';
import { deleteUserFavorites, postUserFavorites } from '@/services/goturkiye';
import { handleGetUserFavorites } from '@/utils/functions';
import Icon from '@/components/base/icon';
import { functions } from '@wap-client/core';

const LikeButton: React.FC<LikeButtonProps> = ({ id }) => {
  const [isLiked, setisLiked] = useState(false);
  const token = Cookie.get('token');

  useEffect(() => {
    const userFavorites: any = Cookie.get('userFavorites') || [];
    setisLiked(!!userFavorites.find((item: string) => item === id));
  }, []);

  const handleLike = (
    evnt: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    evnt.preventDefault();
    evnt.stopPropagation();
    if (token) {
      if (isLiked) {
        deleteUserFavorites(id).then((res) => {
          handleGetUserFavorites();
          setisLiked(false);
        });
      } else {
        postUserFavorites({ recordId: id }).then((res) => {
          handleGetUserFavorites();
          setisLiked(true);
        });
      }
    } else {
      console.log('Beğeni için giriş yapmalısınız popup ı yapılabilir.');
    }
  };

  return (
    <CustomButton
      blur={true}
      style={{
        borderRadius: '50%',
      }}
      padding={false}
      onClick={handleLike}
      className={functions.classnames('like-button', isLiked && 'liked')}
      iconBefore={
        <Icon
          size="small"
          name={isLiked ? 'icon-heart-filled' : 'icon-heart'}
        />
      }
    />
  );
};

export default LikeButton;
