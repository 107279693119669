import { functions } from '@wap-client/core';

import type { IconProps } from './types';

const Icon: React.FunctionComponent<IconProps> = ({
  className,
  fill,
  label,
  name,
  size = 'medium',
  ...rest
}) => {
  const classNames = functions.classnames(
    'c-icon',
    `c-icon--size-${size}`,
    `icon-${name}`,
    name,
    className
  );

  return (
    <span
      className={classNames}
      role="img"
      aria-label={label || name}
      style={{
        color: fill,
        fontSize: typeof size === 'number' ? size : undefined,
      }}
      {...rest}
    />
  );
};

export default Icon;
