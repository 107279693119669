import React from 'react';
import { functions } from '@wap-client/core';
import Anchor from '@/components/base/anchor/Anchor';
import { UICustomButton } from './types';

const CustomButton: React.FC<UICustomButton> = ({
  children,
  iconBefore,
  iconAfter,
  blur,
  background,
  customColor,
  className,
  padding,
  fontSize,
  fontWeight,
  width,
  round,
  opacity = 0.13,
  anchor,
  motion: motionProps,
  ...props
}) => {
  const classNames = functions.classnames(
    'custom-button',
    blur && 'custom-button--blur',
    children && iconBefore && 'custom-button--icon-before',
    children && iconAfter && 'custom-button--icon-after',
    padding === false && 'custom-button--no-padding',
    width === 'full' && 'custom-button--full',
    width === 'max-content' && 'custom-button--max-content',
    className
  );

  const hexToRgb = (hex: string) => {
    let cleanHex = hex.replace('#', '');
    if (cleanHex.length === 3) {
      cleanHex = cleanHex
        .split('')
        .map((char) => char + char)
        .join('');
    }
    const bigint = parseInt(cleanHex, 16);
    return `${(bigint >> 16) & 255}, ${(bigint >> 8) & 255}, ${bigint & 255}`;
  };

  const buttonStyle = {
    ...(background && {
      backgroundColor: `rgba(${hexToRgb(background)}, ${blur ? opacity : '1'})`,
    }),
    ...(customColor && { color: `rgb(${hexToRgb(customColor)})` }),
    ...(fontSize && { fontSize }),
    ...(fontWeight && { fontWeight }),
    ...(width && width !== 'full' && width !== 'max-content' && { width }),
    borderRadius: round,
  };

  const ButtonElement = (
    <button
      className={classNames}
      style={buttonStyle}
      {...motionProps}
      {...(props as any)}
    >
      {iconBefore}
      {children}
      {iconAfter}
    </button>
  );

  return anchor ? <Anchor {...anchor}>{ButtonElement}</Anchor> : ButtonElement;
};

export default CustomButton;
