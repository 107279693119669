import React, { useEffect, useMemo, useRef } from 'react';
import Link from 'next/link';
import { useApp } from '@wap-client/core';
import { path } from '@wap-client/core';
import { useCombineRefs } from '@wap-client/core';
import type { AnchorProps } from './types';
import { motion } from 'framer-motion';

const MotionLink = motion(Link);

const Anchor: React.ForwardRefRenderFunction<HTMLAnchorElement, AnchorProps> = (
  { motion: motionProps, children, href, external, rootRoute, ...rest },
  ref
) => {
  const app = useApp();
  const anchorRef = useRef<HTMLAnchorElement>(null);
  const refs = useCombineRefs<HTMLAnchorElement>(anchorRef, ref);

  // data
  const fullUrl = path.app(app.environment, href);
  const isExternalUrl = fullUrl.startsWith('http');
  const convertedUrl = useMemo((): string => {
    if (rootRoute) {
      if (!isExternalUrl) {
        return `https://${app.environment.url}`;
      }
    }

    return fullUrl;
  }, [isExternalUrl, rootRoute]);

  // effects
  useEffect(() => {
    if (window.location.pathname === fullUrl) {
      anchorRef.current?.setAttribute('aria-current', 'page');
    }
  }, [fullUrl]);

  // renders
  if (isExternalUrl) {
    return (
      <motion.a
        ref={refs}
        href={convertedUrl}
        target="_blank"
        rel="noreferrer noopener"
        {...motionProps}
        {...(rest as any)}
      >
        {children}
      </motion.a>
    );
  }

  return (
    <MotionLink
      href={convertedUrl}
      ref={refs}
      {...motionProps}
      {...(rest as any)}
    >
      {children}
    </MotionLink>
  );
};

export default React.forwardRef(Anchor);
