/* eslint-disable @next/next/no-img-element */
import React, { useEffect, useRef } from 'react';
import { functions } from '@wap-client/core';

import { useApp } from '@wap-client/core';
import { useVar } from '@wap-client/core';
import { path } from '@wap-client/core';

import type { ImageProps } from './types';

const Image: React.FunctionComponent<ImageProps> = (props) => {
  const attributes = { ...props, ...props.source };
  const {
    alt,
    className,
    height,
    id,
    loading = 'lazy',
    src = '',
    srcSet,
    width,
    ...rest
  } = attributes;

  const app = useApp();
  const imageRef = useRef<HTMLImageElement>(null);
  const isEager = useVar(loading === 'eager');
  const isLazy = useVar(loading === 'lazy');
  const classNames = functions.classnames(
    'image',
    {
      'image-loaded': isEager,
      'image-loading': isLazy,
    },
    className
  );

  const getSrcSet = () => {
    if (srcSet) {
      if (typeof srcSet === 'string') {
        return srcSet;
      }

      return srcSet
        .map(({ src, width }) => `${path.asset(app.environment, src)} ${width}`)
        .join(',');
    }
  };

  const getAttributes = () => {
    const { source, srcset, ...otherRest } = rest as any;

    if (isLazy) {
      return {
        'data-src': path.asset(app.environment, src),
        'data-srcset': getSrcSet(),
        onLoad: handleLoad,
        ...otherRest,
      };
    }

    return {
      src: path.asset(app.environment, src),
      srcSet: getSrcSet(),
      ...otherRest,
    };
  };

  useEffect(() => {
    if (isLazy) {
      const image = imageRef.current;

      if (image) {
        if ('loading' in HTMLImageElement.prototype) {
          const srcset = image.getAttribute('data-srcset');

          image.src = path.asset(app.environment, src) || '';
          // image.srcset = srcset || '';
          image.classList.remove('layzload');
          image.removeAttribute('data-src');
          image.removeAttribute('data-srcset');
        } else {
          image.classList.add('lazyload');
        }

        image.removeAttribute('srcset');
      }
    }
  }, [isLazy, src]);

  const handleLoad = (event: React.SyntheticEvent<HTMLImageElement>) => {
    if (event.currentTarget) {
      const parent = event.currentTarget.parentElement;

      if (parent) {
        parent.classList.remove('image-loading');
        parent.classList.add('image-loaded');
      }
    }
  };

  const handleError = (event: React.SyntheticEvent<HTMLImageElement>) => {
    if (event.currentTarget) {
      const parent = event.currentTarget.parentElement;

      if (parent) {
        parent.classList.remove('image-loading');
        parent.classList.add('broken-image');
      }
    }
  };

  return (
    <div className={classNames}>
      <img
        ref={imageRef}
        className="image-node"
        alt={alt}
        width={width}
        height={height}
        loading={loading}
        onError={handleError}
        {...getAttributes()}
      />
    </div>
  );
};

export default Image;
