import React, { ReactElement, useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import Anchor from '@/components/base/anchor';
import Icon from '@/components/base/icon';
import Image from '@/components/base/image';
import CustomButton from '@/components/widgets/custom-button';
import { path, useApp } from '@wap-client/core';
import { functions } from '@wap-client/core';
import { UICard } from './types';

import moment from 'moment';
import useMomentLocale from '@/hooks/useMomentLocale';
import { AnchorProps } from '@wap-client/constants';
import LikeButton from '@/widgets/like-button';

const animations = {
  card: {
    hidden: {
      opacity: 0,
      y: 100,
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: [0.25, 1, 0.5, 1],
      },
    },
  },
};

const Card: React.FC<UICard> = ({
  image,
  title,
  description,
  like,
  lightbox,
  lightboxGalleryId,
  link,
  day,
  className,
  style,
  index,
  showTopList,
  isDiscover = false,
  city,
  district,
  isRiviera,
  motion: motionProps,
  place,
  endDate,
  startDate,
  id = '',
  isContentCity,
}) => {
  const app = useApp();
  const ref = useRef<HTMLDivElement>(null);
  const isInView = useInView(ref, { once: true });
  useMomentLocale(app.language || 'en');

  const renderStartEndDate = (): JSX.Element | null => {
    let resultText = '';
    const xStartDate = moment(startDate);
    const xEndDate = moment(endDate);
    const hourFormat = 'HH:mm';

    if (xStartDate.format('YYYY MM DD') === xEndDate.format('YYYY MM DD')) {
      if (xStartDate.format(hourFormat) === xEndDate.format(hourFormat)) {
        resultText = `${xStartDate.format('DD MMM YYYY')} - ${xStartDate.format(
          hourFormat
        )}`;
      } else {
        resultText = `${xStartDate.format('DD MMM YYYY')} - ${xStartDate.format(
          hourFormat
        )}/${xEndDate.format(hourFormat)}`;
      }
    } else {
      resultText = `${xStartDate.format('DD MMM')}/${xEndDate.format(
        'DD MMM'
      )} ${xStartDate.get('year')} - ${xStartDate.format(
        hourFormat
      )}/${xEndDate.format(hourFormat)}`;
    }

    if (resultText) {
      return <span className="text">{resultText}</span>;
    } else return null;
  };

  const renderDetail = (
    title: string | undefined,
    description: string | undefined
  ) => {
    return (
      <div
        className={`card-detail ${showTopList ? 'card-detail-container' : ''}`}
      >
        {showTopList && (
          <div className="card-detail-container-top-number">{index! + 1}</div>
        )}

        <div className="card-detail-content">
          {title && <div className="card-detail-title">{title}</div>}
          {city && !isContentCity && <p className="card-detail-city">{city}</p>}
          {(place || startDate || endDate) && (
            <div className="event-detail">
              {place && (
                <div className="detail-row place">
                  <Icon name="icon-pin-v2" />
                  <span className="text">{place}</span>
                </div>
              )}
              {(startDate || endDate) && (
                <div className="detail-row date">
                  <Icon name="icon-clock" />
                  {renderStartEndDate()}
                </div>
              )}
            </div>
          )}
          {description && (
            <div
              className="card-detail-desc"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
        </div>
      </div>
    );
  };

  const renderWithAnchor = (children: ReactElement) => {
    if (lightbox) {
      return (
        <a
          data-src={path.asset(app.environment, image?.src)}
          data-fancybox={
            !!lightboxGalleryId ? `gallery-${lightboxGalleryId}` : 'single'
          }
          // data-caption={description}
        >
          {children}
        </a>
      );
    }

    if (link) {
      return <Anchor {...link}>{children}</Anchor>;
    }

    return children;
  };

  const convertDayString = (): string => {
    if (day) {
      const [dayNumber, word] = day.split(' ');
      if (dayNumber && word) {
        if (Number(dayNumber) > 1 && !word.endsWith('s')) {
          return `${day}s`;
        } else if (Number(dayNumber) <= 1 && word.endsWith('s')) {
          return `${dayNumber} ${word.slice(0, -1)}`;
        }
        return day;
      } else if (dayNumber && !word) {
        if (Number(dayNumber) <= 1) {
          return `${dayNumber} ${app.settings.translations['day']}`;
        } else {
          return `${dayNumber} ${app.settings.translations['day']}s`;
        }
      }

      return day;
    }

    return '';
  };

  return (
    <motion.div
      ref={ref}
      variants={animations.card}
      animate={`${isInView ? 'show' : 'hidden'}`}
      initial="hidden"
      viewport={{ once: true, amount: 0.25 }}
      className={functions.classnames(className, 'card', {
        'card-lightbox': lightbox,
      })}
      style={style || undefined}
      {...motionProps}
    >
      <WithAnchor
        dataSrc={path.asset(app.environment, image?.src)}
        dataFancybox={
          !!lightboxGalleryId ? `gallery-${lightboxGalleryId}` : 'single'
        }
        link={link}
        lightbox={lightbox}
      >
        <>
          <div className={functions.classnames('card-thumbnail')}>
            {(like || lightbox) && (
              <div className="card-thumbnail-toolbar">
                {like && <LikeButton id={id} />}
                {lightbox && (
                  <CustomButton
                    blur={true}
                    background="#000"
                    customColor="#FFF"
                    round={50}
                    padding={false}
                    className="card-thumbnail-toolbar-button"
                    iconBefore={
                      <Icon size="medium" fill="white" name="icon-show-image" />
                    }
                  />
                )}
              </div>
            )}

            {district && city && (
              <>
                <CustomButton
                  blur={true}
                  background="#000"
                  customColor="#FFF"
                  round={50}
                  iconAfter={<Icon size={'small'} name="icon-direct-arrow" />}
                  className="card-thumbnail-badge"
                >
                  {city} / {district}
                </CustomButton>
              </>
            )}

            {day && (
              <>
                <CustomButton
                  blur={false}
                  background="#FFF"
                  customColor="#000"
                  opacity={0.5}
                  round={20}
                  iconBefore={
                    <Icon size={'small'} fill="#1980DD" name="icon-km" />
                  }
                  fontSize={14}
                  className="card-thumbnail-day-button"
                >
                  {convertDayString()}
                </CustomButton>
              </>
            )}

            {title && !description && isDiscover && (
              <CustomButton
                className="card-thumbnail-title-button"
                background="#FFF"
                customColor="#000"
                blur={false}
                round={20}
                opacity={0.5}
              >
                {title}
              </CustomButton>
            )}

            <Image className="card-thumbnail-image" {...image} alt={title} />
          </div>

          {!isDiscover && (title || description)
            ? renderDetail(title, description)
            : null}
        </>
      </WithAnchor>
    </motion.div>
  );
};

export default Card;

type WithAnchorProps = {
  link?: AnchorProps;
  children: React.ReactNode;
  lightbox?: boolean;
  dataFancybox?: string;
  dataSrc?: string;
};

const WithAnchor = (props: WithAnchorProps) => {
  if ('link' in props) {
    return <Anchor {...props.link}>{props.children}</Anchor>;
  }

  if ('lightbox' in props) {
    return (
      <a
        data-src={props.dataSrc}
        data-fancybox={props.dataFancybox}
        // data-caption={description}
      >
        {props.children}
      </a>
    );
  }

  return props.children;
};
