import moment from 'moment';
import 'moment/locale/tr';
import 'moment/locale/ar';

const useMomentLocale = (locale: string) => {
  let langShortCode: string = '';

  if (!locale) {
    langShortCode = 'en';
  } else if (locale.length > 2) {
    langShortCode = locale.substring(0, 2);
  } else {
    langShortCode = locale;
  }

  if (['en', 'tr', 'fr'].includes(langShortCode)) {
    moment.locale(langShortCode);
  } else {
    moment.locale('en');
  }
};

export default useMomentLocale;
